import React, { useState, useRef, useEffect } from 'react'
import { Input, Button, Tooltip, Select } from 'antd'
import { MessageOutlined, SendOutlined } from '@ant-design/icons'
import { AuthProvider, useAuth } from '../../AuthContext'
import './index.css'
import {
  InfoCircleOutlined,
  CheckCircleOutlined,
  WarningOutlined
} from '@ant-design/icons'
import AudioRecorder from '../AudioRecorder'
import PrimaryButton from '../ui/Buttons/primaryButton'
import DefaultButton from '../ui/Buttons/defaultButton'
import { LuFileAudio } from 'react-icons/lu'
import { FaArrowUp } from 'react-icons/fa'
import { FaMicrophone } from 'react-icons/fa'
import { RiRobot2Line } from 'react-icons/ri'
import {
  FiEdit2,
  FiRefreshCw,
  FiVolume2,
  FiThumbsUp,
  FiThumbsDown,
  FiShare2,
  FiPause
} from 'react-icons/fi'
import PsychologyOutlinedIcon from '@mui/icons-material/PsychologyOutlined'
import logo from '../../assets/images/logo.png'
import { useMemo } from 'react'
import { MenuOutlined } from '@ant-design/icons'
import debounce from 'lodash/debounce'
import TTSService from '../../services/TTSService'

const MainChat = ({ 
  messages, 
  input, 
  setInput, 
  sendMessage, 
  isLoading,
  selectedLLM,
  defaultModel,
  onModelChange,
  openRouterModels = [],
  onOpenSidebar,
  playTTSAudio,
  isPlaying
}) => {
  const { user, loading } = useAuth()
  const [playingMessageIndex, setPlayingMessageIndex] = useState(null)
  const [localIsPlaying, setLocalIsPlaying] = useState(false)
  const [currentText, setCurrentText] = useState(null)
  const chatDisplayRef = useRef(null);

  const examples = [
    "What can you do?",
    "Tell me a joke",
    "What's the weather like?"
  ]

  const capabilities = [
    "Summarize articles",
    "Set reminders",
    "Perform calculations"
  ]
  
  const limitations = [
    "May provide outdated info",
    "Doesn't have real-time updates",
    "Limited memory"
  ]

  const handleSendMessage = () => {
    sendMessage()
  }

  const handleInputKeyPress = e => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()
      handleSendMessage()
    }
  }

  const handlePlayback = async (messageText, index) => {
    const isCurrentlyPlaying = playingMessageIndex === index && localIsPlaying;
    const isSameText = currentText === messageText;
    
    if (isCurrentlyPlaying) {
      // If currently playing, pause it
      setLocalIsPlaying(false);
      await playTTSAudio(messageText, true);
    } else if (playingMessageIndex === index && !localIsPlaying && isSameText) {
      // If same text is paused, resume it
      setLocalIsPlaying(true);
      TTSService.resumePlayback();
    } else {
      // If starting new playback
      if (playingMessageIndex !== null) {
        TTSService.stopPlayback();
      }
      setPlayingMessageIndex(index);
      setLocalIsPlaying(true);
      setCurrentText(messageText);
      try {
        await playTTSAudio(messageText, false);
      } catch (error) {
        setLocalIsPlaying(false);
        setPlayingMessageIndex(null);
        setCurrentText(null);
      }
    }
  };

  // Reset local state when playback finishes
  useEffect(() => {
    if (!isPlaying && localIsPlaying) {
      setLocalIsPlaying(false);
      setPlayingMessageIndex(null);
      setCurrentText(null);
    }
  }, [isPlaying]);

  // Add useEffect to scroll to bottom whenever messages change
  useEffect(() => {
    if (chatDisplayRef.current) {
      chatDisplayRef.current.scrollTop = chatDisplayRef.current.scrollHeight;
    }
  }, [messages]);

  const getModelOptions = () => {
    switch (selectedLLM) {
      case 'openai':
        return [
          { value: 'gpt-4o', label: 'GPT-4o - High-intelligence flagship model' },
          { value: 'gpt-4o-mini', label: 'GPT-4o mini - Affordable and intelligent small model' },
          { value: 'o1-preview', label: 'o1-preview - Complex reasoning model' },
          { value: 'o1-mini', label: 'o1-mini - Complex reasoning model' },
          { value: 'gpt-4-turbo', label: 'GPT-4 Turbo - Previous high-intelligence model' },
          { value: 'gpt-4', label: 'GPT-4 - Previous high-intelligence model' },
          { value: 'gpt-3.5-turbo', label: 'GPT-3.5 Turbo - Fast, inexpensive model' }
        ]
      case 'claude':
        return [
          { value: 'claude-3-opus-20240229', label: 'Claude 3 Opus - Most capable model' },
          { value: 'claude-3-sonnet-20240229', label: 'Claude 3 Sonnet - Balanced model' },
          { value: 'claude-3-haiku-20240307', label: 'Claude 3 Haiku - Fastest model' }
        ]
      case 'gemini':
        return [
          { value: 'gemini-1.5-pro', label: 'Gemini 1.5 Pro - Most capable' },
          { value: 'gemini-1.5-flash', label: 'Gemini 1.5 Flash - Fast response' }
        ]
      case 'mistral':
        return [
          { value: 'mistral-large-latest', label: 'Mistral Large - Latest top-tier model (v2)' },
          { value: 'mistral-small-latest', label: 'Mistral Small - Enterprise-grade model (v2)' },
          { value: 'ministral-8b-latest', label: 'Ministral 8B - High performance/price ratio' },
          { value: 'ministral-3b-latest', label: 'Ministral 3B - Best edge model' },
          { value: 'codestral-latest', label: 'Codestral - Specialized for coding' }
        ]
      case 'openrouter':
        return openRouterModels.length > 0 ? openRouterModels : [
          { value: 'anthropic/claude-3-opus-20240229', label: 'Claude 3 Opus - Most capable model' },
          { value: 'anthropic/claude-3-sonnet-20240229', label: 'Claude 3 Sonnet - Balanced model' },
          { value: 'google/gemini-pro', label: 'Gemini Pro - Google AI model' },
          { value: 'meta-llama/llama-2-70b-chat', label: 'Llama 2 70B - Meta\'s largest model' },
          { value: 'mistral-ai/mistral-large', label: 'Mistral Large - Latest model' },
          { value: 'mistral-ai/mistral-medium', label: 'Mistral Medium - Balanced model' }
        ]
      default:
        return []
    }
  }

  // Use direct input handling instead
  const handleInputChange = (e) => {
    setInput(e.target.value)
  }

  return (
    <div className="chat-screen-container">
      {/* Add Mobile Header */}
      <div className="mobile-header">
        <button 
          className="menu-button"
          onClick={onOpenSidebar}
        >
          <MenuOutlined style={{ fontSize: '24px' }} />
        </button>
        <span className="mobile-title">AI Chat</span>
        <div style={{ width: '24px' }} /> 
      </div>

      {/* If no messages, show the AI introduction and example prompts */}
      {messages.length === 0 ? (
        <div className="ai-intro-container">
          <h2 className="ai-intro-title">
            Welcome to the New Generation of AI
          </h2>
          <p className="ai-intro-description">
            Use this AI to assist you with a variety of tasks like
            summarization, translation, reminders, and much more!
          </p>

          {/* 3-Column Grid */}
          <div className="ai-grid-container">
            <div className="ai-column">
              <div className="ai-column-header">
                <InfoCircleOutlined className="ai-icon" />
                <h3>Examples</h3>
              </div>
              {examples.map((item, index) => (
                <div key={index} className="ai-grid-item">
                  <div className="ai-inner-grid-item">{item}</div>
                </div>
              ))}
            </div>

            <div className="ai-column">
              <div className="ai-column-header">
                <CheckCircleOutlined className="ai-icon" />
                <h3>Capabilities</h3>
              </div>
              {capabilities.map((item, index) => (
                <div key={index} className="ai-grid-item">
                  <div className="ai-inner-grid-item">{item}</div>
                </div>
              ))}
            </div>

            <div className="ai-column">
              <div className="ai-column-header">
                <WarningOutlined className="ai-icon" />
                <h3>Limitations</h3>
              </div>
              {limitations.map((item, index) => (
                <div key={index} className="ai-grid-item">
                  <div className="ai-inner-grid-item">{item}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div 
          className="chat-display" 
          ref={chatDisplayRef}
        >
          {messages.map((message, index) => (
            <React.Fragment key={index}>
              {message.sender !== 'user' ? (
                <div className="message-bubble-ai">
                    <img 
                      src={logo} 
                      alt="logo" 
                      style={{ 
                        width: '48px', 
                        height: '48px', 
                        minWidth: '35px', 
                        minHeight: '35px',
                        objectFit: 'cover',
                        display: 'block'
                      }} 
                    />

                  <div>
                    <p className="chat-message-ai">{message.text}</p>

                    {/* Icon buttons under the message bubble */}
                    <div className="icon-buttons-container">
                      <button className="icon-button">
                        <Tooltip title="edit">
                          <FiEdit2 style={{ color: "#FF1493" }}/>
                        </Tooltip>
                      </button>
                      <button className="icon-button">
                        <Tooltip title="regenerate">
                          <FiRefreshCw style={{ color: "#9400D3" }}/>
                        </Tooltip>
                      </button>
                      <button 
                        className="icon-button"
                        onClick={() => handlePlayback(message.text, index)}
                      >
                        <Tooltip title={playingMessageIndex === index && localIsPlaying ? 'pause' : 'read aloud'}>
                          {playingMessageIndex === index && localIsPlaying ? (
                            <FiPause style={{ color: "#e53935" }}/>
                          ) : (
                            <FiVolume2 style={{ color: "#e53935" }}/>
                          )}
                        </Tooltip>
                      </button>
                      <button className="icon-button">
                        <Tooltip title="Like">
                          <FiThumbsUp style={{ color: "#00CED1" }}/>
                        </Tooltip>
                      </button>
                      <button className="icon-button">
                        <Tooltip title="dislike">
                          <FiThumbsDown style={{ color: "#FF69B4" }}/>
                        </Tooltip>
                      </button>
                      <button className="icon-button">
                        <Tooltip title="share">
                          <FiShare2 style={{ color: "#ff5722" }}/>
                        </Tooltip>
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="message-bubble-user">
                  <p className="chat-message-user">{message.text}</p>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '35px',
                      width: '35px',
                      minWidth: '35px',
                      minHeight: '35px',
                      borderRadius: '50%',
                      backgroundColor: '#e8056f',
                      fontSize: '18px',
                      color: 'white',
                      flexShrink: 0,
                    }}
                  >
                    {user.email.charAt(0).toUpperCase()}
                  </div>
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
      )}

      {/* Input Field */}
      <div className="main-chat-input-container">
        <div className="input-row">
          <div className="input-with-buttons">
            <Input.TextArea
              placeholder="Type your message..."
              value={input}
              onChange={handleInputChange}
              className="chat-input"
              disabled={isLoading}
              autoSize={{ minRows: 1, maxRows: 6 }}
              onPressEnter={handleInputKeyPress}
            />
            <div className="input-actions">
              <DefaultButton shape="circle">
                <Tooltip title="Record audio">
                  <FaMicrophone />
                </Tooltip>
              </DefaultButton>
              <AudioRecorder />
              <PrimaryButton
                shape="circle"
                icon={<FaArrowUp />}
                onClick={handleSendMessage}
                disabled={isLoading || input.trim() === ''}
              />
            </div>
          </div>
        </div>
        <div className="bottom-row">
          <Select
            value={defaultModel}
            onChange={onModelChange}
            options={getModelOptions()}
            disabled={isLoading}
            className="model-selector"
            dropdownMatchSelectWidth={false}
            showSearch
            optionFilterProp="label"
          />
        </div>
      </div>
    </div>
  )
}

export default React.memo(MainChat)
