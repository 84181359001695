import React, { useState, useEffect, useCallback } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useAuth } from '../AuthContext'
import {
  saveChatSession,
  loadChatSessions,
  deleteChatSession,
  getCurrentSubscription
} from '../DatabaseService'
import ChatList from './ChatList'
import MainChat from './MainChatComponent'
import '../styles/AIChat.css'
import AntDrawer from './Drawer'
import { useSearchParams } from 'react-router-dom'
import { chat, streamChat, AVAILABLE_MODELS } from '../langchain'
import TTSService from '../services/TTSService'
import { message } from 'antd'
import { getValidApiKeys } from '../DatabaseService'

const AIChat = () => {
  const [searchParams] = useSearchParams()
  const [initialMessage, setInitialMessage] = useState(null)
  const [messages, setMessages] = useState([])
  const [input, setInput] = useState(initialMessage || '')
  const [isLoading, setIsLoading] = useState(false)
  const { user } = useAuth()
  const [selectedLLM, setSelectedLLM] = useState('openai')
  const [defaultModel, setDefaultModel] = useState('gpt-3.5-turbo')
  const [sessions, setSessions] = useState([])
  const [currentSessionId, setCurrentSessionId] = useState(null)
  const [openRouterModels, setOpenRouterModels] = useState([])
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)
  const [currentSubscription, setCurrentSubscription] = useState(null)
  const [ttsProvider, setTTSProvider] = useState(
    localStorage.getItem('ttsProvider') || 'elevenlabs'
  )
  const [readAloud, setReadAloud] = useState(
    localStorage.getItem('readAloud') === 'true'
  )
  const [selectedVoice, setSelectedVoice] = useState(
    localStorage.getItem('selectedVoice') || ''
  )
  const [isPlaying, setIsPlaying] = useState(false)
  const [actualKey, setActualKey] = useState(null)
  const [apiKeys, setApiKeys] = useState([])

  // Remove the memoized handler since it's not needed
  const handleSetInput = value => {
    setInput(value)
  }

  // get api keys
  const fetchKeys = async () => {
    try {
      const validKeys = await getValidApiKeys();
      if (!Array.isArray(validKeys)) {
        console.error('Invalid response from getValidApiKeys:', validKeys);
        return [];
      }

      // Map through the valid keys and ensure we have all required properties
      const formattedKeys = validKeys
        .filter(key => key && key.apiKey && key.name) // Filter out invalid entries
        .map(key => ({
          apiKey: key.apiKey,
          name: key.name,
          appKey: key.appKey
        }));

      console.log('Fetched API keys:', formattedKeys.length);
      return formattedKeys;
    } catch (error) {
      console.error('Error fetching keys:', error);
      return []; // Return empty array on error
    }
  };

  useEffect(() => {
    const loadKeys = async () => {
      try {
        const keys = await fetchKeys();
        if (keys && keys.length > 0) {
          setApiKeys(keys);
        } else {
          console.log('No valid API keys found');
        }
      } catch (error) {
        console.error('Error loading API keys:', error);
      }
    };

    loadKeys();
  }, []);

  useEffect(() => {
    const savedLLM = localStorage.getItem('selectedLLM') || 'openai'
    const savedModel = localStorage.getItem('defaultModel') || 'gpt-3.5-turbo'
    console.log('Loading saved settings - LLM:', savedLLM, 'Model:', savedModel)
    setSelectedLLM(savedLLM)
    setDefaultModel(savedModel)
    fetchKeys()
    loadChatHistory()
  }, [user])

  useEffect(() => {
    const fetchModels = async () => {
      if (selectedLLM === 'openrouter') {
        try {
          const apiKeys = JSON.parse(localStorage.getItem('apiKeys')) || {}
          const apiKey = apiKeys.openrouter
          if (apiKey) {
            const models = AVAILABLE_MODELS[selectedLLM] || []
            setOpenRouterModels(models)
          }
        } catch (error) {
          console.error('Error fetching OpenRouter models:', error)
        }
      }
    }

    fetchModels()
  }, [selectedLLM])

  const loadChatHistory = async () => {
    try {
      const chatSessions = await loadChatSessions(user.id)
      setSessions(chatSessions || [])
      // Start with empty messages but don't create a session yet
      setMessages([])
    } catch (error) {
      console.error('Error loading chat history:', error)
      setSessions([])
      setMessages([])
    }
  }

  const selectSession = sessionId => {
    const session = sessions.find(s => s.session_id === sessionId)

    if (session) {
      setCurrentSessionId(sessionId)
      setMessages(session.messages)
    }
  }

  const deleteSession = async sessionId => {
    try {
      await deleteChatSession(sessionId)
      setSessions(sessions.filter(s => s.session_id !== sessionId))
      if (currentSessionId === sessionId) {
        setCurrentSessionId(null)
        setMessages([])
      }
    } catch (error) {
      console.error('Error deleting chat session:', error)
    }
  }

  const generateTitle = async messages => {
    try {
      // Create a more restrictive prompt that emphasizes brevity
      const prompt = `Generate an extremely concise title (maximum 3-4 words) that captures the main topic of this conversation. Do not use punctuation or quotes. Here's the conversation:\n\n${messages
        .slice(0, 2) // Only use the first exchange to keep titles focused on initial topic
        .map(m => `${m.sender}: ${m.text}`)
        .join('\n')}`
      
      const apiKeys = JSON.parse(localStorage.getItem('apiKeys')) || {}
      const apiKey = apiKeys[selectedLLM]
      const title = await chat(
        prompt,
        selectedLLM,
        defaultModel,
        apiKey,
        !!currentSubscription
      )
      // Clean up the title - remove quotes, periods, and limit length
      return title.replace(/['",.]/g, '').trim().split(' ').slice(0, 4).join(' ')
    } catch (error) {
      console.error('Error generating title:', error)
      return 'New Chat'
    }
  }

  const handleTTSPlayback = async (text, isPausing) => {
    if (!readAloud) return

    try {
      if (isPausing) {
        TTSService.pausePlayback()
        setIsPlaying(false)
      } else if (TTSService.isPaused) {
        TTSService.resumePlayback()
        setIsPlaying(true)
      } else {
        setIsPlaying(true)
        await TTSService.convertText(text, ttsProvider, selectedVoice)
        setIsPlaying(false)
      }
    } catch (error) {
      console.error('TTS Error:', error)
      message.error(
        'Failed to convert text to speech. Please check your settings.'
      )
      setIsPlaying(false)
    }
  }

  const sendMessage = async () => {
    if (!input.trim()) return
    setIsLoading(true)

    try {
      const apiKeys = JSON.parse(localStorage.getItem('apiKeys')) || {}
      let apiKey = apiKeys[selectedLLM]

      if (!apiKey && !currentSubscription?.length) {
        throw new Error(
          `No API key found for ${selectedLLM}. Please add it in the settings or subscribe to one of the plans`
        )
      }
      if (currentSubscription?.length < 1 && actualKey === null) {
        throw new Error(
          `API key found for ${selectedLLM} is wrong. Please add correct one in the settings or subscribe to one of the plans`
        )
      }
      if (currentSubscription?.length < 1 && actualKey !== null) {
        apiKey = actualKey
      }
      if (currentSubscription?.length > 0) {
        apiKey = actualKey
      }

      const userMessage = { text: input, sender: 'user' }
      const newMessages = [...messages, userMessage]
      setMessages(newMessages)
      setInput('')
      handleSetInput('')

      const response = await chat(
        input,
        selectedLLM,
        defaultModel,
        apiKey,
        !!currentSubscription
      )

      const aiMessage = { text: response, sender: 'ai' }
      const updatedMessages = [...newMessages, aiMessage]
      setMessages(updatedMessages)

      // Only create a new session if this is the first message exchange
      if (!currentSessionId) {
        const newSessionId = uuidv4()
        const title = await generateTitle(updatedMessages)
        
        // Create new session with the conversation
        const newSession = {
          session_id: newSessionId,
          title,
          messages: updatedMessages
        }
        
        setCurrentSessionId(newSessionId)
        setSessions(prevSessions => [newSession, ...prevSessions])
        
        // Save to database
        await saveChatSession(user.id, newSessionId, title, updatedMessages)
      } else {
        // Update existing session
        const title = await generateTitle(updatedMessages)
        setSessions(prevSessions => 
          prevSessions.map(session => 
            session.session_id === currentSessionId 
              ? { ...session, title, messages: updatedMessages }
              : session
          )
        )
        await saveChatSession(user.id, currentSessionId, title, updatedMessages)
      }
    } catch (error) {
      console.error('Error:', error)
      setMessages(prev => [
        ...prev,
        { text: input, sender: 'user' },
        { text: `Error: ${error.message}`, sender: 'ai' }
      ])
    } finally {
      setIsLoading(false)
    }
  }

  const handleModelChange = value => {
    setDefaultModel(value)
    localStorage.setItem('defaultModel', value)
  }

  const handleOpenSidebar = () => {
    setIsSidebarOpen(true)
  }

  const getCurrentUserSubscription = async user => {
    try {
      const result = await getCurrentSubscription(user.id)
      setCurrentSubscription(result)
    } catch (error) {
      console.error('Error fetching subscription:', error)
    }
  }

  useEffect(() => {
    if (user) {
      getCurrentUserSubscription(user)
    }
  }, [user])

  // Add back createNewSession but with updated logic
  const createNewSession = () => {
    // Clear current session and messages
    setCurrentSessionId(null)
    setMessages([])
    // Don't create a new session in the list until user sends first message
  }

  return (
    <div className='ai-chat-container'>
      <div className='chat-screen-container'>
        <MainChat
          messages={messages}
          input={input}
          setInput={handleSetInput}
          sendMessage={sendMessage}
          isLoading={isLoading}
          selectedLLM={selectedLLM}
          defaultModel={defaultModel}
          onModelChange={handleModelChange}
          openRouterModels={openRouterModels}
          onOpenSidebar={handleOpenSidebar}
          playTTSAudio={handleTTSPlayback}
          isPlaying={isPlaying}
        />
      </div>

      <div className='chat-history-list-button'>
        <AntDrawer
          open={isSidebarOpen}
          onClose={() => setIsSidebarOpen(false)}
          onAddNew={createNewSession}
        >
          <ChatList
            sessions={sessions}
            onSelectSession={selectSession}
            onDeleteSession={deleteSession}
            currentSessionId={currentSessionId}
            onNewSession={createNewSession}
          />
        </AntDrawer>
      </div>
    </div>
  )
}

export default React.memo(AIChat)
