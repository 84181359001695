import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Spin, message, Typography } from 'antd';
import { 
  LeftOutlined, 
  LoadingOutlined, 
  SendOutlined,
  SoundOutlined,
  FileTextOutlined,
  RobotOutlined,
  ClockCircleOutlined
} from '@ant-design/icons';
import { supabase, fetchResourceById, fetchTranscription, fetchSummary, handleSummaryGeneration } from '../DatabaseService';
import ReactPlayer from 'react-player';
import 'react-h5-audio-player/lib/styles.css';
import '../styles/PlayerScreen.css';
import ReactMarkdown from 'react-markdown';
import { useMediaQuery } from 'react-responsive';

const { Title, Paragraph } = Typography;

const PlayerScreen = ({ user }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [resource, setResource] = useState(null);
  const [mediaUrl, setMediaUrl] = useState(null);
  const [transcript, setTranscript] = useState([]);
  const [summary, setSummary] = useState(null);
  const [recordingName, setRecordingName] = useState('');
  const [isSummaryLoaded, setIsSummaryLoaded] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 768 });

  useEffect(() => {
    const loadResource = async () => {
      try {
        if (!user || !id || isSummaryLoaded) return;
        
        setIsLoading(true);
        
        // First try to fetch existing summary
        console.log('🔍 Checking for existing summary...');
        const existingSummary = await fetchSummary(id);
        
        if (existingSummary) {
          console.log('✅ Found existing summary:', {
            summaryId: existingSummary.id,
            preview: existingSummary.content.substring(0, 100) + '...'
          });
          setSummary(existingSummary.content);
          setIsSummaryLoaded(true);
        } else {
          // Only generate if no existing summary
          console.log('🤖 No existing summary found, generating new summary...');
          const generatedSummary = await handleSummaryGeneration(id, user.id);
          if (generatedSummary) {
            console.log('✅ Generated new summary successfully');
            setSummary(generatedSummary);
            setIsSummaryLoaded(true);
          }
        }

        // Load other resource data
        const data = await fetchResourceById(id);
        if (!data) throw new Error('Resource not found');

        setResource(data);
        setRecordingName(data.name);

        const filePath = data.url.split('/recordings/')[1];
        const { data: signedData, error: signedError } = await supabase
          .storage
          .from('recordings')
          .createSignedUrl(filePath, 3600);

        if (signedError) throw signedError;
        setMediaUrl(signedData.signedUrl);

        const transcriptionData = await fetchTranscription(id);
        if (transcriptionData?.content) {
          setTranscript([{
            text: transcriptionData.content,
            timestamp: '00:00'
          }]);
        }

        setIsLoading(false);
      } catch (error) {
        console.error('❌ Error loading resource:', error);
        message.error(error.message);
        setIsLoading(false);
      }
    };

    loadResource();
  }, [id, user, isSummaryLoaded]);

  const [duration, setDuration] = useState(0);

  const handleDuration = (duration) => {
    setDuration(duration);
  };

  const renderMediaPlayer = () => {
    if (!mediaUrl) return null;

    if (resource?.type === 'audio') {
      return (
        <div className="player-card">
          <div className="player-header">
            <SoundOutlined className="player-icon" />
            <span>Audio Player</span>
          </div>
          <ReactPlayer
            url={mediaUrl}
            controls
            width="100%"
            height={isMobile ? "40px" : "50px"}
            onReady={() => {
              console.log('Player is ready');
              console.log('Media URL:', mediaUrl);
            }}
          />
          <div className="duration-display">
            Duration: {resource?.duration ? resource.duration.toFixed(2) : 'Loading...'} seconds
          </div>
        </div>
      );
    }

    return (
      <div className="player-card">
        <ReactPlayer
          url={mediaUrl}
          controls
          width="100%"
          height={isMobile ? "200px" : "auto"}
          className="video-player"
        />
      </div>
    );
  };
  const sendToNotion = async (content, type) => {
    try {
      const response = await fetch('https://solo-recall.vercel.app/notion', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          transcriptionText: content,
          title: recordingName
        })
      });

      if (response.ok) {
        message.success(`${type} sent to Notion successfully`);
      } else {
        throw new Error('Failed to send to Notion');
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  if (isLoading) {
    return (
      <div className="loading-container">
        <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
      </div>
    );
  }

  return (
    <div className="player-screen">
      <div className="main-panel">
        <div className="header">
          <Button 
            type="link" 
            icon={<LeftOutlined />} 
            onClick={() => navigate('/files')}
            className="back-button"
          >
            Back to Files
          </Button>
          <Title level={3} className="title">{recordingName}</Title>
        </div>

        {renderMediaPlayer()}

        <div className="summary-card">
          <div className="card-header">
            <RobotOutlined className="card-icon" />
            <span>AI Summary</span>
          </div>
          <div className="card-content markdown-content">
            {summary ? (
              <ReactMarkdown>{summary}</ReactMarkdown>
            ) : (
              'No summary available yet.'
            )}
          </div>
          {summary && (
            <Button
              type="primary"
              icon={<SendOutlined />}
              onClick={() => sendToNotion(summary, 'Summary')}
              className="action-button"
            >
              Send to Notion
            </Button>
          )}
        </div>
      </div>

      <div className="transcript-panel">
        <div className="card-header">
          <FileTextOutlined className="card-icon" />
          <span>Transcript</span>
        </div>
        
        {transcript.length > 0 ? (
          <>
            <Button
              type="primary"
              icon={<SendOutlined />}
              onClick={() => sendToNotion(transcript[0].text, 'Transcript')}
              className="action-button"
            >
              Send to Notion
            </Button>
            
            <div className="transcript-content">
              {transcript.map((segment, index) => (
                <div key={index} className="transcript-segment">
                  <div className="timestamp">
                    <ClockCircleOutlined />
                    {segment.timestamp}
                  </div>
                  <div className="text">{segment.text}</div>
                </div>
              ))}
            </div>
          </>
        ) : (
          <div className="empty-state">
            No transcript available
          </div>
        )}
      </div>
    </div>
  );
};

export default PlayerScreen;
