import React from 'react';
import { Button as AntdButton } from 'antd';
import './index.css'; // Import the custom styles

const DefaultButton = ({
  shape = 'default', // default shape prop
  size = 'middle',
  loading = false,
  disabled = false,
  onClick,
  children,
  ...rest
}) => {
  return (
    <AntdButton
      className="default-button"
      shape={shape}
      size={size}
      loading={loading}
      disabled={disabled}
      onClick={onClick}
      {...rest}
    >
      {children}
    </AntdButton>
  );
};

export default DefaultButton;
