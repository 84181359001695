import React from 'react'
import { FiTrash2 } from 'react-icons/fi'
import '../styles/ChatList.css'


const ChatList = ({
  sessions,
  onSelectSession,
  onDeleteSession,
  currentSessionId,
  onNewSession
}) => {
  return (
    <div className='chat-list'>
      <ul>
        {sessions.map(session => (
          <li
            key={session.session_id}
            className={session.session_id === currentSessionId ? 'active' : ''}
            onClick={() => onSelectSession(session.session_id)}
          >
            {session.title}
            <button
              onClick={e => {
                e.stopPropagation()
                onDeleteSession(session.session_id)
              }}
            >
              <FiTrash2 />
            </button>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default ChatList
