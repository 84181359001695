import React, { useState, useRef, useCallback, useEffect } from 'react';
import { FiPlay, FiPause, FiSquare } from 'react-icons/fi';
import { message } from 'antd';
import { 
  supabase, 
  uploadTranscription, 
  uploadAudioRecording,
  generateSummary,
  handleSummaryGeneration
} from '../DatabaseService';
import { useNavigate } from 'react-router-dom';

const AudioRecording = ({ onClose }) => {
  const [isRecording, setIsRecording] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [recordingTime, setRecordingTime] = useState(0);
  const [transcript, setTranscript] = useState('');
  const [uploadStatus, setUploadStatus] = useState('');
  const [user, setUser] = useState(null);
  
  const recognition = useRef(null);
  const timerRef = useRef(null);
  const finalDurationRef = useRef(0);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);

  const navigate = useNavigate();

  useEffect(() => {
    const getUser = async () => {
      const { data: { user: authUser } } = await supabase.auth.getUser();
      if (!authUser) {
        console.error('No authenticated user found');
        message.error('Please sign in to record audio');
        onClose();
        return;
      }
      setUser(authUser);
    };

    getUser();
  }, [onClose]);

  useEffect(() => {
    console.log('Initializing Web Speech Recognition');
    if ('webkitSpeechRecognition' in window) {
      recognition.current = new window.webkitSpeechRecognition();
      recognition.current.continuous = true;
      recognition.current.interimResults = true;
      recognition.current.lang = 'en-US';

      recognition.current.onstart = () => {
        console.log('Speech recognition started');
        setUploadStatus('Listening...');
      };

      recognition.current.onend = () => {
        console.log('Speech recognition ended');
      };

      recognition.current.onresult = (event) => {
        console.log('Speech recognition result received:', event.results);
        let finalTranscript = '';
        for (let i = event.resultIndex; i < event.results.length; ++i) {
          if (event.results[i].isFinal) {
            const transcriptPart = event.results[i][0].transcript;
            console.log('Final transcript part:', transcriptPart);
            finalTranscript += ' ' + transcriptPart;
          }
        }
        if (finalTranscript.trim()) {
          console.log('Adding to transcript:', finalTranscript);
          setTranscript(prevTranscript => {
            const newTranscript = (prevTranscript + finalTranscript).trim();
            console.log('New complete transcript:', newTranscript);
            return newTranscript;
          });
        }
      };

      recognition.current.onerror = (event) => {
        console.error('Speech recognition error:', {
          error: event.error,
          message: event.message,
          timestamp: new Date().toISOString()
        });
        setUploadStatus(`Error: ${event.error}`);
      };
    } else {
      console.warn('Web Speech Recognition not supported in this browser');
      setUploadStatus('Speech recognition not supported in this browser');
    }

    return () => {
      if (recognition.current) {
        console.log('Cleaning up speech recognition');
        recognition.current.stop();
      }
    };
  }, []);

  const startTimer = useCallback(() => {
    if (timerRef.current === null) {
      timerRef.current = setInterval(() => {
        setRecordingTime(prev => prev + 1);
      }, 1000);
    }
  }, []);

  const stopTimer = useCallback(() => {
    if (timerRef.current !== null) {
      clearInterval(timerRef.current);
      timerRef.current = null;
    }
  }, []);

  const startRecording = useCallback(async () => {
    if (!recognition.current) {
      setUploadStatus('Speech recognition not supported');
      return;
    }

    try {
      // Initialize audio recording
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream, {
        mimeType: 'audio/webm;codecs=opus'
      });
      
      mediaRecorderRef.current = mediaRecorder;
      audioChunksRef.current = [];

      // Set up media recorder event handlers
      mediaRecorder.ondataavailable = (event) => {
        console.log('Audio data available:', event.data.size);
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data);
        }
      };

      // Start both speech recognition and audio recording
      recognition.current.start();
      mediaRecorder.start();
      
      setIsRecording(true);
      setTranscript('');
      setRecordingTime(0);
      startTimer();
      console.log('Recording started - Audio and Speech Recognition');
    } catch (error) {
      console.error('Error starting recording:', error);
      setUploadStatus(`Failed to start recording: ${error.message}`);
    }
  }, [startTimer]);

  const pauseRecording = useCallback(() => {
    if (recognition.current && isRecording) {
      recognition.current.stop();
      setIsPaused(true);
      stopTimer();
    }
  }, [isRecording, stopTimer]);

  const resumeRecording = useCallback(() => {
    if (recognition.current && isRecording && isPaused) {
      recognition.current.start();
      setIsPaused(false);
      startTimer();
    }
  }, [isRecording, isPaused, startTimer]);

  const stopRecording = useCallback(async () => {
    console.log('Stopping recording...');
    if (recognition.current && isRecording) {
      recognition.current.stop();
      setIsRecording(false);
      setIsPaused(false);
      stopTimer();
      finalDurationRef.current = recordingTime;

      if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
        return new Promise((resolve) => {
          mediaRecorderRef.current.onstop = async () => {
            console.log('MediaRecorder stopped, chunks:', audioChunksRef.current.length);
            const audioBlob = new Blob(audioChunksRef.current, { 
              type: 'audio/webm;codecs=opus' 
            });
            console.log('Audio blob created:', audioBlob.size, audioBlob.type);
            await saveToSupabase(audioBlob);
            resolve();
          };
          mediaRecorderRef.current.stop();
          mediaRecorderRef.current.stream.getTracks().forEach(track => track.stop());
        });
      }
    }
  }, [isRecording, stopTimer, recordingTime, saveToSupabase]);

  useEffect(() => {
    return () => {
      if (timerRef.current !== null) {
        clearInterval(timerRef.current);
      }
      if (recognition.current) {
        recognition.current.stop();
      }
    };
  }, []);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const saveToSupabase = useCallback(async (audioBlob) => {
    try {
      if (!user) {
        throw new Error('No authenticated user');
      }

      setUploadStatus('Starting upload process...');
      
      // First save the audio recording
      const recordingData = await uploadAudioRecording(user.id, audioBlob);
      
      if (!recordingData?.success) {
        throw new Error('Failed to save audio recording');
      }

      console.log('✅ Audio recording saved:', recordingData);

      if (transcript) {
        setUploadStatus('Uploading transcription...');
        
        try {
          console.log('🎯 Starting transcription upload process...');
          const transcriptionResult = await uploadTranscription(
            user.id,
            transcript,
            recordingData.data.id,
            `${recordingData.data.id}_transcript.txt`
          );

          if (transcriptionResult.success) {
            console.log('✅ Transcription uploaded successfully:', transcriptionResult.data);
            
            // Generate summary immediately after successful transcription
            setUploadStatus('Generating summary...');
            try {
              console.log('🤖 Starting summary generation...');
              const summaryResult = await generateSummary(
                transcript,
                recordingData.data.id,
                user.id
              );

              if (summaryResult?.content) {
                console.log('✅ Summary generated successfully:', {
                  summaryId: summaryResult.id,
                  contentPreview: summaryResult.content.substring(0, 100) + '...'
                });
                message.success('Recording, transcription, and summary completed');
              } else {
                throw new Error('Summary generation returned invalid result');
              }
            } catch (summaryError) {
              console.error('❌ Summary generation error:', summaryError);
              message.warning('Recording and transcription saved, but summary generation failed');
            }
          } else {
            throw new Error('Failed to save transcription');
          }
        } catch (error) {
          console.error('❌ Process error:', error);
          message.error(error.message);
        }
      }

      setUploadStatus('Processing complete');
      navigate(`/player/${recordingData.data.id}`);
    } catch (error) {
      console.error('❌ Save process error:', error);
      setUploadStatus(`Error: ${error.message}`);
      message.error('Failed to save recording');
    }
  }, [transcript, navigate, user]);

  return (
    <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-4 text-gray-800 dark:text-white">Voice Recording</h2>
      <div className="text-4xl font-mono mb-4 text-gray-700 dark:text-gray-300">
        {formatTime(recordingTime)}
      </div>
      {uploadStatus && (
        <div className="mb-4 text-sm font-semibold text-blue-600 dark:text-blue-400">
          {uploadStatus}
        </div>
      )}
      <div className="mb-4 p-4 bg-gray-100 dark:bg-gray-700 rounded-lg">
        <p className="text-gray-800 dark:text-gray-200">{transcript || 'Start speaking...'}</p>
      </div>
      <div className="space-x-4">
        {!isRecording ? (
          <button
            onClick={startRecording}
            className="px-6 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
          >
            <FiPlay className="inline-block mr-2" /> Start Recording
          </button>
        ) : (
          <>
            {isPaused ? (
              <button
                onClick={resumeRecording}
                className="px-6 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
              >
                <FiPlay className="inline-block mr-2" /> Resume
              </button>
            ) : (
              <button
                onClick={pauseRecording}
                className="px-6 py-2 bg-yellow-500 text-white rounded-md hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-opacity-50"
              >
                <FiPause className="inline-block mr-2" /> Pause
              </button>
            )}
            <button
              onClick={stopRecording}
              className="px-6 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
            >
              <FiSquare className="inline-block mr-2" /> Stop
            </button>
          </>
        )}
        <button
          onClick={onClose}
          className="px-6 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-opacity-50"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default AudioRecording;
