import React, { useState, useEffect } from 'react'
import { Input, Select, Switch, Button } from 'antd'
import '../styles/Settings.css'
import { AVAILABLE_MODELS } from '../langchain'
import TTSService from '../services/TTSService'
import axios from 'axios'
import { message } from 'antd'

const Settings = () => {
  const [selectedLLM, setSelectedLLM] = useState('openai')
  const [defaultModel, setDefaultModel] = useState('gpt-4o')
  const [apiKeys, setApiKeys] = useState({
    openai: '',
    claude: '',
    gemini: '',
    mistral: '',
    openrouter: '',
    groq: '',
    ollama: '',
    elevenlabs: '',
    google: ''
  })
  const [selectedVoice, setSelectedVoice] = useState('')
  const [readAloud, setReadAloud] = useState(false)
  const [openRouterModels, setOpenRouterModels] = useState([])
  const [ttsProvider, setTTSProvider] = useState(
    localStorage.getItem('ttsProvider') || 'elevenlabs'
  )
  const [activeSection, setActiveSection] = useState('ai-models') // Track active section

  useEffect(() => {
    const savedLLM = localStorage.getItem('selectedLLM') || 'openai'
    const savedModel = localStorage.getItem('defaultModel') || 'gpt-3.5-turbo'
    const savedApiKeys = JSON.parse(localStorage.getItem('apiKeys')) || {}

    // Set default voice to Rachel if none is selected
    const savedVoice =
      localStorage.getItem('selectedVoice') || '21m00Tcm4TlvDq8ikWAM'

    console.log('Loading API keys:', {
      availableKeys: Object.keys(savedApiKeys),
      elevenLabsKeyPresent: !!savedApiKeys.elevenlabs,
      envKeyPresent:
        !!process.env.REACT_APP_ELEVEN_LABS_API_KEY ||
        !!process.env.REACT_APP_ELEVEN_API_KEY,
      selectedVoice: savedVoice
    })

    setSelectedLLM(savedLLM)
    setDefaultModel(savedModel)
    setApiKeys(prevKeys => ({ ...prevKeys, ...savedApiKeys }))
    setSelectedVoice(savedVoice)
    setReadAloud(localStorage.getItem('readAloud') === 'true')
  }, [])

  useEffect(() => {
    const fetchModels = async () => {
      if (selectedLLM === 'openrouter') {
        try {
          const apiKey = apiKeys.openrouter
          if (apiKey) {
            const models = AVAILABLE_MODELS[selectedLLM] || []
            setOpenRouterModels(models)
          }
        } catch (error) {
          console.error('Error fetching OpenRouter models:', error)
        }
      }
    }

    fetchModels()
  }, [selectedLLM, apiKeys.openrouter])

  const handleLLMChange = value => {
    console.log('LLM changed to:', value)
    setSelectedLLM(value)
    localStorage.setItem('selectedLLM', value)

    // Reset model to default when LLM changes
    const defaultModelForLLM = getDefaultModelForLLM(value)
    console.log('Setting default model for', value, ':', defaultModelForLLM)
    setDefaultModel(defaultModelForLLM)
    localStorage.setItem('defaultModel', defaultModelForLLM)
  }

  const getDefaultModelForLLM = llm => {
    switch (llm) {
      case 'openai':
        return 'gpt-4o'
      case 'claude':
        return 'claude-3-sonnet-20240229'
      case 'gemini':
        return 'gemini-1.5-pro'
      case 'mistral':
        return 'mistral-small-latest' // Using a more stable model as default
      case 'openrouter':
        return 'anthropic/claude-3-opus-20240229'
      case 'groq':
        // return 'distil-whisper-large-v3-en'
        return 'mixtral-8x7b-32768'
      default:
        return 'gpt-4o'
    }
  }

  const handleModelChange = value => {
    setDefaultModel(value)
    localStorage.setItem('defaultModel', value)
  }

  const handleApiKeyChange = (llm, value) => {
    console.log(
      'Saving API key for:',
      llm,
      'Value prefix:',
      value ? value.substring(0, 10) + '...' : 'none'
    )
    const newApiKeys = { ...apiKeys, [llm]: value }
    setApiKeys(newApiKeys)

    // Log what we're saving to localStorage
    console.log('Saving to localStorage:', {
      keys: Object.keys(newApiKeys),
      elevenLabsKeyPresent: !!newApiKeys.elevenlabs,
      googleKeyPresent: !!newApiKeys.google
    })

    // Handle special cases for TTS services
    if (llm === 'elevenlabs') {
      localStorage.setItem('elevenlabsApiKey', value)
    } else if (llm === 'google') {
      localStorage.setItem('googleApiKey', value)
    }

    localStorage.setItem('apiKeys', JSON.stringify(newApiKeys))
  }

  const handleSave = async () => {
    try {
      if (apiKeys.elevenlabs) {
        // Verify the API key before saving
        const response = await axios.get('https://api.elevenlabs.io/v1/user', {
          headers: {
            'xi-api-key': apiKeys.elevenlabs
          }
        })

        if (response.status === 200) {
          message.success('ElevenLabs settings saved successfully')
        }
      } else {
        // If the key is empty, remove it from localStorage
        localStorage.removeItem('elevenlabsApiKey')
        message.success('ElevenLabs API key removed')
      }

      // Save other settings
      localStorage.setItem('selectedVoice', selectedVoice)
      localStorage.setItem('readAloud', readAloud.toString())
      localStorage.setItem('ttsProvider', ttsProvider)
    } catch (error) {
      console.error('Error saving settings:', error)
      message.error('Invalid ElevenLabs API key. Please check and try again.')
    }
  }

  const getModelOptions = () => {
    switch (selectedLLM) {
      case 'openai':
        return [
          {
            value: 'gpt-4o',
            label: 'GPT-4o - High-intelligence flagship model'
          },
          {
            value: 'gpt-4o-mini',
            label: 'GPT-4o mini - Affordable and intelligent small model'
          },
          {
            value: 'o1-preview',
            label: 'o1-preview - Complex reasoning model'
          },
          { value: 'o1-mini', label: 'o1-mini - Complex reasoning model' },
          {
            value: 'gpt-4-turbo',
            label: 'GPT-4 Turbo - Previous high-intelligence model'
          },
          { value: 'gpt-4', label: 'GPT-4 - Previous high-intelligence model' },
          {
            value: 'gpt-3.5-turbo',
            label: 'GPT-3.5 Turbo - Fast, inexpensive model'
          }
        ]
      case 'claude':
        return [
          {
            value: 'claude-3-opus-20240229',
            label: 'Claude 3 Opus - Most capable model'
          },
          {
            value: 'claude-3-sonnet-20240229',
            label: 'Claude 3 Sonnet - Balanced model'
          },
          {
            value: 'claude-3-haiku-20240307',
            label: 'Claude 3 Haiku - Fastest model'
          }
        ]
      case 'groq':
        return [
          {
            value: 'mixtral-8x7b-32768',
            label: 'mixtral-8x7b-32768'
          },
          {
            value: 'distil-whisper-large-v3-en',
            label: 'distil-whisper-large-v3-en'
          },
          {
            value: 'gemma2-9b-it',
            label: 'gemma2-9b-it'
          },
          {
            value: 'gemma-7b-it',
            label: 'gemma-7b-it'
          }
        ]
      case 'gemini':
        return [
          { value: 'gemini-1.5-pro', label: 'Gemini 1.5 Pro - Most capable' },
          {
            value: 'gemini-1.5-flash',
            label: 'Gemini 1.5 Flash - Fast response'
          }
        ]
      case 'mistral':
        return [
          {
            value: 'mistral-large-latest',
            label: 'Mistral Large - Latest top-tier model (v2)'
          },
          {
            value: 'mistral-small-latest',
            label: 'Mistral Small - Enterprise-grade model (v2)'
          },
          {
            value: 'ministral-8b-latest',
            label: 'Ministral 8B - High performance/price ratio'
          },
          {
            value: 'ministral-3b-latest',
            label: 'Ministral 3B - Best edge model'
          },
          {
            value: 'codestral-latest',
            label: 'Codestral - Specialized for coding'
          }
        ]
      case 'openrouter':
        return openRouterModels.length > 0
          ? openRouterModels
          : [
              // Fallback options if API call fails
              {
                value: 'anthropic/claude-3-opus-20240229',
                label: 'Claude 3 Opus - Most capable model'
              },
              {
                value: 'anthropic/claude-3-sonnet-20240229',
                label: 'Claude 3 Sonnet - Balanced model'
              },
              {
                value: 'google/gemini-pro',
                label: 'Gemini Pro - Google AI model'
              },
              {
                value: 'meta-llama/llama-2-70b-chat',
                label: "Llama 2 70B - Meta's largest model"
              },
              {
                value: 'mistral-ai/mistral-large',
                label: 'Mistral Large - Latest model'
              },
              {
                value: 'mistral-ai/mistral-medium',
                label: 'Mistral Medium - Balanced model'
              }
            ]
      default:
        return []
    }
  }

  const getProviderOptions = () => {
    return TTSService.getAvailableProviders()
  }

  const handleTTSProviderChange = value => {
    setTTSProvider(value)
    localStorage.setItem('ttsProvider', value)
  }

  const handleReadAloudChange = checked => {
    setReadAloud(checked)
    localStorage.setItem('readAloud', checked.toString())
  }

  const handleVoiceChange = value => {
    setSelectedVoice(value)
    localStorage.setItem('selectedVoice', value)
  }

  return (
    <div className='settings-container'>
      <div className='settings-sidebar'>
        <div className='settings-sidebar-header'>
          <h2>Settings</h2>
        </div>

        <div className='settings-nav'>
          <div className='settings-nav-group'>
            <div className='settings-nav-group-title'>Preferences</div>
            <div
              className={`settings-nav-item ${
                activeSection === 'ai-models' ? 'active' : ''
              }`}
              onClick={() => setActiveSection('ai-models')}
            >
              <span>AI Models</span>
            </div>
            <div
              className={`settings-nav-item ${
                activeSection === 'tts' ? 'active' : ''
              }`}
              onClick={() => setActiveSection('tts')}
            >
              <span>Text to Speech</span>
            </div>
            <div
              className={`settings-nav-item ${
                activeSection === 'api-keys' ? 'active' : ''
              }`}
              onClick={() => setActiveSection('api-keys')}
            >
              <span>API Keys</span>
            </div>
          </div>
        </div>
      </div>

      <div className='settings-content'>
        <div className='settings-content-header'>
          <div>
            <h1>
              {activeSection === 'ai-models'
                ? 'AI Model Settings'
                : activeSection === 'tts'
                ? 'Text-to-Speech Settings'
                : 'API Configuration'}
            </h1>
            <div className='help-text'>
              {activeSection === 'ai-models'
                ? 'Configure your AI model preferences'
                : activeSection === 'tts'
                ? 'Manage text-to-speech settings'
                : 'Manage your API keys securely'}
            </div>
          </div>
        </div>

        {activeSection === 'ai-models' && (
          <div className='settings-section'>
            <div className='settings-section-header'>
              <div className='settings-section-title'>Model Selection</div>
              <div className='settings-section-description'>
                Choose your preferred AI model and provider
              </div>
            </div>

            <div className='setting-item'>
              <label>AI Provider</label>
              <Select
                value={selectedLLM}
                onChange={handleLLMChange}
                style={{ width: '100%' }}
              >
                <Select.Option value='openai'>OpenAI</Select.Option>
                <Select.Option value='claude'>Claude</Select.Option>
                <Select.Option value='gemini'>Gemini</Select.Option>
                <Select.Option value='mistral'>Mistral</Select.Option>
                <Select.Option value='openrouter'>OpenRouter</Select.Option>
                <Select.Option value='groq'>Groq</Select.Option>
                <Select.Option value='ollama'>
                  Ollama (Open Source)
                </Select.Option>
              </Select>
            </div>

            <div className='setting-item'>
              <label>Model</label>
              <Select
                value={defaultModel}
                onChange={handleModelChange}
                style={{ width: '100%' }}
              >
                {getModelOptions().map(option => (
                  <Select.Option key={option.value} value={option.value}>
                    {option.label}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </div>
        )}

        {activeSection === 'tts' && (
          <div className='settings-section'>
            <div className='settings-section-header'>
              <div className='settings-section-title'>Voice Settings</div>
              <div className='settings-section-description'>
                Configure text-to-speech preferences
              </div>
            </div>

            <div className='read-aloud-section'>
              <label>Enable Read Aloud</label>
              <Switch
                checked={readAloud}
                onChange={handleReadAloudChange}
                checkedChildren='On'
                unCheckedChildren='Off'
              />
            </div>

            <div className='setting-item'>
              <label>TTS Provider</label>
              <Select
                value={ttsProvider}
                onChange={handleTTSProviderChange}
                style={{ width: '100%' }}
              >
                {TTSService.getAvailableProviders().map(provider => (
                  <Select.Option key={provider.value} value={provider.value}>
                    {provider.label}
                  </Select.Option>
                ))}
              </Select>
            </div>

            <div className='setting-item'>
              <label>Voice</label>
              <Select
                value={selectedVoice}
                onChange={handleVoiceChange}
                style={{ width: '100%' }}
              >
                {TTSService.getAvailableVoices(ttsProvider).map(voice => (
                  <Select.Option key={voice.value} value={voice.value}>
                    {voice.label}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </div>
        )}

        {activeSection === 'api-keys' && (
          <div className='settings-section'>
            <div className='settings-section-header'>
              <div className='settings-section-title'>API Keys</div>
              <div className='settings-section-description'>
                Manage your API keys for different services
              </div>
            </div>

            <div className='setting-warning'>
              <span>
                Please ensure you're using a secure connection when entering API
                keys.
              </span>
            </div>

            {Object.keys(apiKeys).map(llm => (
              <div key={llm} className='setting-item'>
                <label>
                  {llm.charAt(0).toUpperCase() + llm.slice(1)} API Key
                </label>
                <Input.Password
                  value={apiKeys[llm]}
                  onChange={e => handleApiKeyChange(llm, e.target.value)}
                  placeholder={`Enter ${llm} API key`}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default Settings
