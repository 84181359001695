// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Mobile-only footer */
.mobile-footer {
  display: none; /* Hidden by default */
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px;
  /* padding: 1.25px; */
  background-color: var(--sidebar-bg-color); /* Customize background color */
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.1);
  z-index: 100;
}

@media (max-width: 768px) {
  /* Display footer only on screens 768px or smaller (mobile) */
  .mobile-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 5px;
  }
}

/* .main-chat-input-container {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.25rem 1rem;
  background-color: var(--sidebar-bg-color);
  border-radius: 10px;
}

.chat-input {
  flex: 1;
}

.chat-input-buttons {
  display: flex;
  align-items: center;
  gap: 0.5rem;
} */
`, "",{"version":3,"sources":["webpack://./src/components/ui/Footer/index.css"],"names":[],"mappings":"AAAA,uBAAuB;AACvB;EACE,aAAa,EAAE,sBAAsB;EACrC,eAAe;EACf,SAAS;EACT,OAAO;EACP,QAAQ;EACR,YAAY;EACZ,qBAAqB;EACrB,yCAAyC,EAAE,+BAA+B;EAC1E,yCAAyC;EACzC,YAAY;AACd;;AAEA;EACE,6DAA6D;EAC7D;IACE,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,cAAc;EAChB;AACF;;AAEA;;;;;;;;;;;;;;;;;;GAkBG","sourcesContent":["/* Mobile-only footer */\n.mobile-footer {\n  display: none; /* Hidden by default */\n  position: fixed;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  height: 60px;\n  /* padding: 1.25px; */\n  background-color: var(--sidebar-bg-color); /* Customize background color */\n  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.1);\n  z-index: 100;\n}\n\n@media (max-width: 768px) {\n  /* Display footer only on screens 768px or smaller (mobile) */\n  .mobile-footer {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    padding: 0 5px;\n  }\n}\n\n/* .main-chat-input-container {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  gap: 1rem;\n  padding: 0.25rem 1rem;\n  background-color: var(--sidebar-bg-color);\n  border-radius: 10px;\n}\n\n.chat-input {\n  flex: 1;\n}\n\n.chat-input-buttons {\n  display: flex;\n  align-items: center;\n  gap: 0.5rem;\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
