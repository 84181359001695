import { createClient } from '@supabase/supabase-js'
import { message } from 'antd' // Add this import
import { chat } from './langchain'

// const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseUrl = 'https://blfrvmapphmzvushkvea.supabase.co'
// const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;
const supabaseAnonKey =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImJsZnJ2bWFwcGhtenZ1c2hrdmVhIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjEzNDIyOTAsImV4cCI6MjAzNjkxODI5MH0.lS3S-z2pMEnRs7zIBRr1bxLM00dhlk2q3pGCetlPYAU'

if (!supabaseUrl || !supabaseAnonKey) {
  throw new Error('Supabase URL or Anon Key is missing. Check your .env file.')
}

export const supabase = createClient(supabaseUrl, supabaseAnonKey, {
  auth: {
    persistSession: true,
    autoRefreshToken: true,
    detectSessionInUrl: true
  }
})

const generateFileName = type => {
  const now = new Date()
  const formattedDate = now
    .toLocaleString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false
    })
    .replace(/[/,:]/g, '_')
    .replace(/\s/g, '')

  if (type === 'audio') {
    return `SR_audio_${formattedDate}.webm`
  }
  return `${type}_${formattedDate}.webm`
}

export const fetchRecordings = async userId => {
  try {
    const { data, error } = await supabase
      .from('recordings')
      .select('*')
      .eq('user_id', userId)
      .order('created_at', { ascending: false })

    if (error) throw error
    return data
  } catch (error) {
    console.error('Error fetching recordings:', error)
    throw error
  }
}

export const fetchFoldersWithRecordings = async userId => {
  try {
    const { data: folders, error: foldersError } = await supabase
      .from('folders')
      .select('*')
      .eq('user_id', userId)

    if (foldersError) throw foldersError

    const { data: recordings, error: recordingsError } = await supabase
      .from('recordings')
      .select('*')
      .eq('user_id', userId)
      .order('created_at', { ascending: false })

    if (recordingsError) throw recordingsError

    const validRecordings = recordings.filter(recording => recording !== null)

    const foldersWithRecordings = folders.map(folder => ({
      ...folder,
      recordings: validRecordings.filter(
        recording => recording.folder_id === folder.id
      )
    }))

    const rootRecordings = validRecordings.filter(
      recording => recording.folder_id === null
    )

    return { folders: foldersWithRecordings, rootRecordings }
  } catch (error) {
    console.error('Error fetching folders with recordings:', error)
    throw error
  }
}

export const createFolder = async (
  userId,
  folderName,
  parentFolderId = null
) => {
  try {
    const { data, error } = await supabase
      .from('folders')
      .insert({
        user_id: userId,
        name: folderName,
        parent_folder_id: parentFolderId
      })
      .select()
      .single()

    if (error) throw error
    return data
  } catch (error) {
    console.error('Error creating folder:', error)
    throw error
  }
}

export const deleteRecording = async recordingId => {
  try {
    console.log(`Attempting to delete recording with ID: ${recordingId}`)

    const { error: transcriptionError } = await supabase
      .from('transcriptions')
      .delete()
      .eq('recording_id', recordingId)

    if (transcriptionError) {
      console.error('Error deleting transcriptions:', transcriptionError)
      return {
        success: false,
        error: `Failed to delete transcriptions: ${transcriptionError.message}`
      }
    }

    const { data: recording, error: fetchError } = await supabase
      .from('recordings')
      .select('*')
      .eq('id', recordingId)
      .single()

    if (fetchError) {
      console.error('Error fetching recording:', fetchError)
      return {
        success: false,
        error: `Failed to fetch recording: ${fetchError.message}`
      }
    }

    const { error: storageError } = await supabase.storage
      .from('recordings')
      .remove([recording.url.split('/').pop()])

    if (storageError) {
      console.error('Error deleting file from storage:', storageError)
      return {
        success: false,
        error: `Failed to delete file from storage: ${storageError.message}`
      }
    }

    const { error: deleteError } = await supabase
      .from('recordings')
      .delete()
      .eq('id', recordingId)

    if (deleteError) {
      console.error('Error deleting recording:', deleteError)
      return {
        success: false,
        error: `Failed to delete recording: ${deleteError.message}`
      }
    }

    console.log(`Successfully deleted recording with ID: ${recordingId}`)
    return { success: true }
  } catch (error) {
    console.error('Unexpected error deleting recording:', error)
    return {
      success: false,
      error: `Unexpected error occurred: ${error.message}`
    }
  }
}

export const renameItem = async (itemId, newName, isFolder) => {
  try {
    const table = isFolder ? 'folders' : 'recordings'
    const { data, error } = await supabase
      .from(table)
      .update({ name: newName })
      .eq('id', itemId)
      .select()
      .single()

    if (error) throw error
    return data
  } catch (error) {
    console.error(`Error renaming ${isFolder ? 'folder' : 'recording'}:`, error)
    throw error
  }
}

export const uploadRecording = async (userId, file, type, duration) => {
  try {
    console.log(
      `Uploading recording for user ${userId}, type: ${type}, duration: ${duration}`
    )
    const fileName = generateFileName(type)
    const filePath = `${userId}/${fileName}`

    // Ensure proper MIME type is set for audio files
    const uploadOptions = {
      contentType: type === 'audio' ? 'audio/webm' : 'video/webm'
    }

    const { data, error } = await supabase.storage
      .from('recordings')
      .upload(filePath, file, uploadOptions)

    if (error) {
      console.error('Error uploading file to storage:', error)
      throw error
    }

    const { data: urlData } = supabase.storage
      .from('recordings')
      .getPublicUrl(filePath)

    const roundedDuration = Math.max(Math.round(duration), 1)

    const { data: recordingData, error: recordingError } = await supabase
      .from('recordings')
      .insert({
        user_id: userId,
        name: fileName,
        url: urlData.publicUrl,
        type: type,
        duration: roundedDuration,
        metadata: {
          mimeType: type === 'audio' ? 'audio/webm' : 'video/webm',
          originalName: file.name
        },
        folder_id: null
      })
      .select()
      .single()

    if (recordingError) {
      console.error('Error inserting recording data:', recordingError)
      throw recordingError
    }

    console.log('Recording data inserted successfully:', recordingData)
    return recordingData
  } catch (error) {
    console.error('Error uploading recording:', error)
    throw error
  }
}

export const uploadAudioRecording = async (userId, blob, duration, fileName) => {
  console.log('Starting audio upload:', {
    userId,
    blobSize: blob.size,
    blobType: blob.type,
    duration,
    fileName
  });

  try {
    const filePath = `${userId}/${fileName}`;
    console.log('Generated file path for audio:', filePath);

    const { data, error } = await supabase.storage
      .from('recordings')
      .upload(filePath, blob, {
        cacheControl: '3600',
        upsert: false,
        contentType: 'audio/webm;codecs=opus'
      });

    if (error) {
      console.error('Error uploading audio:', error);
      throw error;
    }

    // Insert into 'recordings' table
    const { data: recordingData, error: recordingError } = await supabase
      .from('recordings')
      .insert([
        {
          id: data.path.split('/')[1], // Assuming the ID is the second segment
          user_id: userId,
          name: fileName,
          url: data.path,
          type: 'audio',
          metadata: {
            mimeType: 'audio/webm;codecs=opus',
            originalName: fileName,
            duration: duration
          }
        }
      ])
      .select()
      .single();

    if (recordingError) {
      console.error('Database entry error:', recordingError);
      throw recordingError;
    }

    // Verify the entry was created
    const { data: verifyData, error: verifyError } = await supabase
      .from('recordings')
      .select('*')
      .eq('id', recordingData.id)
      .single();

    console.log('Database verification for audio:', {
      data: verifyData,
      error: verifyError
    });

    return recordingData;
  } catch (error) {
    console.error('Detailed upload error:', {
      message: error.message,
      stack: error.stack,
      cause: error.cause
    });
    throw error;
  }
};

export const uploadScreenRecording = async (userId, blob, duration) => {
  return uploadRecording(userId, blob, 'screen', duration)
}

export const updateRecordingFolder = async (recordingId, folderId) => {
  try {
    const { data, error } = await supabase
      .from('recordings')
      .update({ folder_id: folderId })
      .eq('id', recordingId)
      .select()
      .single()

    if (error) throw error
    return data
  } catch (error) {
    console.error('Error updating recording folder:', error)
    throw error
  }
}

export const fetchResourceById = async resourceId => {
  try {
    const { data, error } = await supabase
      .from('recordings')
      .select('*')
      .eq('id', resourceId)
      .single()

    if (error) {
      if (error.code === 'PGRST116') {
        console.log('DatabaseService: Resource not found')
        return null
      }
      throw error
    }
    return data
  } catch (error) {
    console.error('Error fetching resource:', error)
    throw error
  }
}

export const uploadTranscription = async (userId, transcriptContent, recordingId, fileName) => {
  try {
    console.log('Starting transcription upload:', {
      userId,
      recordingId,
      fileName,
      transcriptLength: transcriptContent?.length
    });

    // Insert directly into 'transcriptions' table
    const { data: transcriptionRecord, error: transcriptionError } = await supabase
      .from('transcriptions')
      .insert({
        recording_id: recordingId,
        user_id: userId,
        content: transcriptContent,
        created_at: new Date().toISOString()
      })
      .select()
      .single();

    if (transcriptionError) {
      console.error('Error saving transcription:', transcriptionError);
      return { success: false, error: transcriptionError };
    }

    console.log('Transcription saved successfully:', transcriptionRecord);
    return { success: true, data: transcriptionRecord };
  } catch (error) {
    console.error('Error in uploadTranscription:', error);
    return { success: false, error };
  }
};

export const fetchAllRecordings = async userId => {
  try {
    const { data, error } = await supabase
      .from('recordings')
      .select('*')
      .eq('user_id', userId)
      .order('created_at', { ascending: false })

    if (error) throw error
    return data
  } catch (error) {
    console.error('Error fetching recordings:', error)
    throw error
  }
}

export const saveChatSession = async (userId, sessionId, title, messages) => {
  try {
    // console.log(userId, ': User', sessionId, ': sessionId')
    const { data, error } = await supabase
      .from('chat_sessions')
      .upsert(
        { user_id: userId, session_id: sessionId, title, messages },
        { onConflict: 'session_id', returning: 'minimal' }
      )

    if (error) throw error
    return data
  } catch (error) {
    console.error('Error saving chat session:', error)
    throw error
  }
}

export const loadChatSessions = async userId => {
  const { data, error } = await supabase
    .from('chat_sessions')
    .select('*')
    .eq('user_id', userId)
    .order('created_at', { ascending: false })

  if (error) throw error
  return data
}

export const deleteChatSession = async sessionId => {
  const { error } = await supabase
    .from('chat_sessions')
    .delete()
    .eq('session_id', sessionId)

  if (error) throw error
}

export const fetchTranscription = async recordingId => {
  try {
    const { data, error } = await supabase
      .from('transcriptions')
      .select('*')
      .eq('recording_id', recordingId)
      .single()

    if (error) {
      if (error.code === 'PGRST116') {
        console.log('No transcription found for this recording')
        return null
      }
      throw error
    }

    return data
  } catch (error) {
    console.error('Error fetching transcription:', error)
    return null
  }
}

export const fetchSummary = async (recordingId) => {
  try {
    console.log('fetchSummary: Attempting to fetch summary for recording:', recordingId);
    
    const { data, error } = await supabase
      .from('summaries')
      .select('*')
      .eq('recording_id', recordingId)
      .order('created_at', { ascending: false })
      .limit(1)
      .single();

    if (error) {
      if (error.code === 'PGRST116') {
        console.log('fetchSummary: No summary found for this recording');
        return null;
      }
      console.error('fetchSummary: Error:', error);
      throw error;
    }

    console.log('fetchSummary: Found existing summary:', {
      summaryId: data.id,
      preview: data.content.substring(0, 100) + '...'
    });
    
    return data;
  } catch (error) {
    if (error.code === 'PGRST116') {
      console.log('fetchSummary: No summary found for this recording');
      return null;
    }
    console.error('fetchSummary: Error:', error);
    throw error;
  }
};

export const updateRecordingName = async (recordingId, newName) => {
  try {
    const { data: currentRecording, error: fetchError } = await supabase
      .from('recordings')
      .select('*')
      .eq('id', recordingId)
      .single()

    if (fetchError) throw fetchError

    const sanitizedNewName = sanitizeFileName(newName)
    const oldPath = currentRecording.url.split('/').slice(-2).join('/')
    const newPath = `${currentRecording.user_id}/${sanitizedNewName}`

    // Check if the old file exists
    const { data: fileExists, error: checkError } = await supabase.storage
      .from('recordings')
      .list(currentRecording.user_id, {
        limit: 1,
        offset: 0,
        sortBy: { column: 'name', order: 'asc' },
        search: oldPath.split('/').pop()
      })

    if (checkError || !fileExists || fileExists.length === 0) {
      console.error(`File not found: ${oldPath}`)
      // Update database record even if file is not found
      const { data, error } = await supabase
        .from('recordings')
        .update({ name: sanitizedNewName })
        .eq('id', recordingId)
        .select()
        .single()

      if (error) throw error
      return data
    }

    // Rename the file in storage
    const { error: moveError } = await supabase.storage
      .from('recordings')
      .move(oldPath, newPath)

    if (moveError) {
      console.error('Error moving file:', moveError)
      throw moveError
    }

    // Update the URL in the database
    const { data: urlData } = supabase.storage
      .from('recordings')
      .getPublicUrl(newPath)

    const { data, error } = await supabase
      .from('recordings')
      .update({ name: sanitizedNewName, url: urlData.publicUrl })
      .eq('id', recordingId)
      .select()
      .single()

    if (error) throw error
    return data
  } catch (error) {
    console.error('Error updating recording name:', error)
    throw error
  }
}

export const handleSummaryGeneration = async (recordingId, userId) => {
  try {
    console.log('🔄 handleSummaryGeneration: Starting for recording:', recordingId);
    
    // First check if we already have a summary
    const existingSummary = await fetchSummary(recordingId);
    
    if (existingSummary) {
      console.log('✅ handleSummaryGeneration: Using existing summary:', {
        summaryId: existingSummary.id,
        preview: existingSummary.content.substring(0, 100) + '...'
      });
      return existingSummary.content;
    }

    // If no summary exists, get the transcription
    console.log('🔍 Fetching transcription...');
    const transcription = await fetchTranscription(recordingId);
    
    if (!transcription?.content) {
      console.error('❌ No transcription found');
      return null;
    }

    console.log('📝 Transcription found:', {
      length: transcription.content.length,
      preview: transcription.content.substring(0, 100) + '...'
    });

    console.log('🤖 Generating new summary');
    // Generate and save the summary
    const summary = await generateSummary(
      transcription.content,
      recordingId,
      userId
    );

    if (!summary?.content) {
      console.error('❌ No summary content generated');
      return null;
    }

    console.log('✅ Summary generated successfully:', {
      length: summary.content.length,
      preview: summary.content.substring(0, 100) + '...'
    });

    return summary.content;
  } catch (error) {
    console.error('❌ handleSummaryGeneration: Error:', error);
    throw error;
  }
};

export const generateSummary = async (transcriptionText, recordingId, userId) => {
  try {
    console.log('=== SUMMARY GENERATION START ===');
    console.log('Input parameters:', {
      recordingId,
      userId,
      transcriptionText,
      textLength: transcriptionText?.length
    });

    if (!transcriptionText?.trim()) {
      console.error('❌ generateSummary: Empty or invalid transcription text');
      throw new Error('Valid transcription text is required');
    }

    const selectedLLM = localStorage.getItem('selectedLLM') || 'openai';
    const defaultModel = localStorage.getItem('defaultModel') || 'gpt-3.5-turbo';
    const apiKeys = JSON.parse(localStorage.getItem('apiKeys')) || {};
    const subscription = await getCurrentSubscription(userId);
    
    console.log('🔧 Configuration:', {
      selectedLLM,
      defaultModel,
      hasApiKey: !!apiKeys[selectedLLM],
      hasSubscription: !!subscription,
      subscriptionDetails: subscription
    });
    
    const apiKey = apiKeys[selectedLLM];

    if (!apiKey && !subscription) {
      console.error('❌ No API key or subscription found');
      throw new Error('No API key or subscription available');
    }

    // Define the prompts
    const systemPrompt = `As a professional summarizer, create a comprehensive and well-structured summary using the following format:

# Summary Overview
[Provide a brief 1-2 sentence overview of the content]

## Key Points
• [First key point with bold **keywords**]
• [Second key point with bold **keywords**]
• [Additional key points as needed]

## Detailed Analysis
[Provide a paragraph that expands on the key points, using markdown formatting for emphasis]

## Action Items
• [If any action items or tasks are mentioned, list them here with **owner/deadline** if specified]
• [Format as "Action: **what needs to be done** - Owner: **who** - Due: **when**"]

## Decisions Made
• [If any decisions or conclusions are mentioned, list them here]
• [Use bold for **key decisions**]

## Follow-up Items
• [List any items requiring follow-up or further discussion]
• [Include any **pending questions** or areas needing clarification]

## Notable Quotes
> [If there are any significant direct quotes from the transcript, include them here]

## Technical Details
• [If any technical specifications, numbers, or data points are mentioned]
• [Format as "**metric/spec**: value"]

## Context & Purpose
[Add a brief paragraph about the context or purpose of the recording, if evident from the content]

## Next Steps
• [Summarize any mentioned next steps or future actions]
• [Include any **deadlines** or **timelines** mentioned]

---
Note: Only include sections that are relevant to the content provided.
[End of Notes, Message #1]`;

    const userPrompt = `Please analyze and summarize the following transcript, using the format specified above. Focus ONLY on the content provided:\n\n${transcriptionText}`;

    console.log('📤 Sending request to LLM service');
    const response = await chat(
      `${systemPrompt}\n\n${userPrompt}`,
      selectedLLM,
      defaultModel,
      apiKey,
      !!subscription
    );

    if (!response) {
      console.error('❌ No response received from LLM');
      throw new Error('Failed to generate summary from LLM');
    }

    console.log('📥 Received LLM response:', {
      responseLength: response.length,
      preview: response.substring(0, 100) + '...'
    });

    // Save summary to Supabase without user_id field
    console.log('💾 Saving summary to database...');
    const { data, error: saveError } = await supabase
      .from('summaries')
      .insert({
        recording_id: recordingId,
        content: response,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString()
      })
      .select()
      .single();

    if (saveError) {
      console.error('❌ Database error:', saveError);
      throw saveError;
    }

    console.log('✅ Summary saved successfully:', {
      summaryId: data.id,
      recordingId: data.recording_id,
      contentLength: data.content?.length
    });
    console.log('=== SUMMARY GENERATION COMPLETE ===');
    
    return data;
  } catch (error) {
    console.error('❌ Summary generation failed:', error);
    throw error;
  }
};

const sanitizeFileName = fileName => {
  return fileName.replace(/['"]/g, '').replace(/[^a-zA-Z0-9._-]/g, '_') // Replace invalid characters
}

export const getCurrentSubscription = async userId => {
  try {
    // Fetch the current active subscription for the given user_id
    const { data, error } = await supabase
      .from('subscriptions')
      .select('*')
      .eq('user_id', userId)
      .eq('is_active', true)
      // .single();
      .limit(1)

    // Error handling
    if (error) {
      console.error('Error fetching current subscription:', error.message)
      return null
    }

    console.log('subscription', data)

    // Return the subscription data
    return data
  } catch (err) {
    console.error('Unexpected error fetching subscription:', err.message)
    return null
  }
}

export const getSubscriptions = async userId => {
  try {
    // Fetch the current active subscription for the given user_id
    const { data, error } = await supabase
      .from('subscriptions')
      .select('*')
      .eq('user_id', userId)

    // Error handling
    if (error) {
      console.error('Error fetching current subscription:', error.message)
      return null
    }

    // Sort the subscriptions by start_date in descending order (latest first)
    const sortedData = data.sort(
      (a, b) => new Date(b.start_date) - new Date(a.start_date)
    )
    return sortedData
  } catch (err) {
    console.error('Unexpected error fetching subscription:', err.message)
    return null
  }
}

export const getSubscription = async userId => {
  try {
    // Fetch the current active subscription for the given user_id
    const { data, error } = await supabase
      .from('subscriptions')
      .select('*')
      .eq('user_id', userId)
      .eq('is_active', true)
      .single()
    // .limit(1)

    // Error handling
    if (error) {
      console.error('Error fetching current subscription:', error.message)
      return null
    }

    console.log('subscription', data)

    // Return the subscription data
    return data
  } catch (err) {
    console.error('Unexpected error fetching subscription:', err.message)
    return null
  }
}

// Get the actual api key for AI models
export const getValidApiKeys = async () => {
  try {
    // Get apiKeys from localStorage and parse it
    const storedKeys = localStorage.getItem('apiKeys');
    if (!storedKeys) {
      console.warn('No API keys found in localStorage');
      return [];
    }

    const apiKeys = JSON.parse(storedKeys);
    if (!apiKeys || typeof apiKeys !== 'object') {
      console.warn('Invalid API keys format in localStorage');
      return [];
    }

    // Filter out models where the key is empty
    const validKeys = Object.entries(apiKeys)
      .filter(([model, key]) => key && typeof key === 'string' && key.trim()) // Keep only entries with non-empty keys
      .map(([model, key]) => ({
        model,
        appKey: key.trim()
      }));

    if (validKeys.length === 0) {
      console.warn('No valid API keys found after filtering');
      return [];
    }

    // Query Supabase for matching api_keys and names
    const { data, error } = await supabase
      .from('ai_models')
      .select('apiKey, appKey, name')
      .in('appKey', validKeys.map(k => k.appKey));

    if (error) {
      console.error('Error fetching API models:', error.message);
      return [];
    }

    // Ensure each returned item has the required properties
    const validatedData = data
      .filter(item => item.apiKey && item.name)
      .map(item => ({
        apiKey: item.apiKey,
        appKey: item.appKey,
        name: item.name
      }));

    console.log('Fetched valid API models:', validatedData.length);
    return validatedData;
  } catch (err) {
    console.error('Unexpected error fetching API models:', err.message);
    return [];
  }
};

export const getSummaryForRecording = async (recordingId) => {
  return await fetchSummary(recordingId);
};
