import axios from 'axios';

class TTSService {
  constructor() {
    this.elevenLabsBaseUrl = 'https://api.elevenlabs.io/v1';
    this.googleTTSBaseUrl = 'https://texttospeech.googleapis.com/v1';
    this.currentAudio = null;
    this.isPaused = false;
    
    // Initialize providers first
    this.providers = {
      elevenlabs: {
        name: 'ElevenLabs',
        voices: {
          'Rachel': '21m00Tcm4TlvDq8ikWAM',
          'Domi': 'AZnzlk1XvdvUeBnXmlld',
          'Bella': 'EXAVITQu4vr4xnSDxMaL',
          'Antoni': 'ErXwobaYiN019PkySvjV',
          'Elli': 'MF3mGyEYCl7XYWbV9V6O',
          'Josh': 'TxGEqnHWrfWFTfGW9XjX',
          'Arnold': 'VR6AewLTigWG4xSOukaG',
          'Adam': 'pNInz6obpgDQGcFmaJgB',
          'Sam': 'yoZ06aMxZJJ28mfd3POQ'
        }
      },
      webspeech: {
        name: 'Web Speech (Free)',
        voices: {}
      },
      google: {
        name: 'Google TTS',
        voices: {
          'en-US-Neural2-A': 'en-US-Neural2-A',
          'en-US-Neural2-C': 'en-US-Neural2-C',
          'en-US-Neural2-D': 'en-US-Neural2-D',
          'en-US-Neural2-E': 'en-US-Neural2-E',
          'en-US-Neural2-F': 'en-US-Neural2-F',
          'en-US-Neural2-G': 'en-US-Neural2-G',
          'en-US-Neural2-H': 'en-US-Neural2-H',
          'en-US-Neural2-I': 'en-US-Neural2-I',
          'en-US-Neural2-J': 'en-US-Neural2-J'
        }
      },
      openai: {
        name: 'OpenAI TTS (Free with API)',
        voices: {
          'Alloy': 'alloy',
          'Echo': 'echo',
          'Fable': 'fable',
          'Onyx': 'onyx',
          'Nova': 'nova',
          'Shimmer': 'shimmer'
        }
      }
    };

    this.initWebSpeechVoices();
    this.loadGoogleVoices();
  }

  stopPlayback() {
    if (this.currentAudio) {
      this.currentAudio.pause();
      this.currentAudio.currentTime = 0;
      this.currentAudio = null;
    }
    if (window.speechSynthesis) {
      window.speechSynthesis.cancel();
    }
    this.isPaused = false;
  }

  pausePlayback() {
    if (this.currentAudio) {
      this.currentAudio.pause();
      this.isPaused = true;
    }
    if (window.speechSynthesis) {
      window.speechSynthesis.pause();
    }
  }

  resumePlayback() {
    if (this.currentAudio && this.isPaused) {
      this.currentAudio.play();
      this.isPaused = false;
    }
    if (window.speechSynthesis) {
      window.speechSynthesis.resume();
    }
  }

  isPlaying() {
    return this.currentAudio !== null && !this.isPaused;
  }initWebSpeechVoices() {
    if (typeof window !== 'undefined' && window.speechSynthesis) {
      const loadVoices = () => {
        const voices = window.speechSynthesis.getVoices();
        
        // Debug log to show all available voices
        console.log('All available voices:', voices.map(voice => ({
          name: voice.name,
          lang: voice.lang,
          default: voice.default,
          localService: voice.localService,
          voiceURI: voice.voiceURI
        })));
        
        // Filter and store English voices
        const englishVoices = voices.filter(voice => voice.lang.includes('en-'));
        console.log('English voices:', englishVoices.map(voice => ({
          name: voice.name,
          lang: voice.lang,
          default: voice.default
        })));
        
        // Update the webspeech voices
        if (this.providers && this.providers.webspeech) {
          this.providers.webspeech.voices = {};
          englishVoices.forEach(voice => {
            this.providers.webspeech.voices[voice.name] = voice.name;
          });
        }
      };

      // Load voices immediately if available
      loadVoices();
      
      // Also load when voices change (needed for some browsers)
      window.speechSynthesis.onvoiceschanged = loadVoices;
    }
  }

  async convertWithElevenLabs(text, voiceId) {
    try {
      const apiKey = localStorage.getItem('elevenlabsApiKey') || 
                    process.env.REACT_APP_ELEVEN_LABS_API_KEY || 
                    process.env.REACT_APP_ELEVEN_API_KEY;

      if (!apiKey) {
        throw new Error('ElevenLabs API key not found. Please add it in settings or subscribe to a plan.');
      }

      // First, verify the API key
      await axios.get(`${this.elevenLabsBaseUrl}/user`, {
        headers: {
          'xi-api-key': apiKey
        }
      });

      // Use Rachel's voice ID as default if no valid voice ID is provided
      let finalVoiceId = voiceId || '21m00Tcm4TlvDq8ikWAM';

      const response = await axios.post(
        `${this.elevenLabsBaseUrl}/text-to-speech/${finalVoiceId}/stream`,
        {
          text,
          model_id: 'eleven_multilingual_v2',
          voice_settings: {
            stability: 0.5,
            similarity_boost: 0.75,
            style: 0.0,
            use_speaker_boost: true
          }
        },
        {
          headers: {
            'xi-api-key': apiKey,
            'Content-Type': 'application/json',
            'Accept': 'audio/mpeg'
          },
          responseType: 'arraybuffer'
        }
      );

      const audioBlob = new Blob([response.data], { type: 'audio/mpeg' });
      const audio = new Audio(URL.createObjectURL(audioBlob));
      this.currentAudio = audio;
      
      return new Promise((resolve, reject) => {
        audio.onended = () => {
          this.currentAudio = null;
          URL.revokeObjectURL(audio.src);
          resolve(true);
        };
        audio.onerror = (error) => {
          this.currentAudio = null;
          URL.revokeObjectURL(audio.src);
          reject(error);
        };
        audio.play().catch(reject);
      });
    } catch (error) {
      console.error('ElevenLabs TTS Error:', error);
      throw error;
    }
  }

  // Helper method to validate voice IDs
  isValidVoiceId(voiceId) {
    const validVoiceIds = Object.values(this.providers.elevenlabs.voices);
    return validVoiceIds.includes(voiceId);
  }

  async convertWithGoogle(text, voiceId) {
    try {
      const apiKeys = JSON.parse(localStorage.getItem('apiKeys')) || {};
      const apiKey = apiKeys.google || 
                    localStorage.getItem('googleApiKey') || 
                    process.env.REACT_APP_GOOGLE_TTS_API_KEY;

      console.log('Google TTS API Key status:', {
        fromApiKeys: !!apiKeys.google,
        fromLocalStorage: !!localStorage.getItem('googleApiKey'),
        fromEnv: !!process.env.REACT_APP_GOOGLE_TTS_API_KEY,
        finalKeyExists: !!apiKey,
        selectedVoice: voiceId
      });

      if (!apiKey) {
        throw new Error('Google TTS API key not found. Please add it in settings or subscribe to a plan.');
      }

      // Get available voices first
      const voicesResponse = await axios.get(
        `${this.googleTTSBaseUrl}/voices?key=${apiKey}`,
        {
          params: { languageCode: 'en-US' }
        }
      );

      // Use the provided voiceId or default to the first available voice
      const finalVoiceId = voiceId || voicesResponse.data.voices[0].name;

      const response = await axios.post(
        `${this.googleTTSBaseUrl}/text:synthesize?key=${apiKey}`,
        {
          input: { text },
          voice: { 
            languageCode: 'en-US',
            name: finalVoiceId
          },
          audioConfig: { 
            audioEncoding: 'MP3',
            pitch: 0,
            speakingRate: 1
          }
        }
      );

      // Convert base64 to audio
      const audioContent = response.data.audioContent;
      const binaryString = window.atob(audioContent);
      const bytes = new Uint8Array(binaryString.length);
      for (let i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }

      const audioBlob = new Blob([bytes], { type: 'audio/mp3' });
      const audio = new Audio(URL.createObjectURL(audioBlob));
      this.currentAudio = audio;

      return new Promise((resolve, reject) => {
        audio.onended = () => {
          this.currentAudio = null;
          URL.revokeObjectURL(audio.src);
          resolve(true);
        };
        audio.onerror = (error) => {
          this.currentAudio = null;
          URL.revokeObjectURL(audio.src);
          reject(error);
        };
        audio.play().catch(reject);
      });
    } catch (error) {
      console.error('Google TTS Error:', error);
      throw error.response?.data?.error || error;
    }
  }

  async getVoices() {
    try {
      const apiKey = localStorage.getItem('elevenlabsApiKey');
      if (!apiKey) {
        return this.providers.elevenlabs.voices; // Return default voices if no API key
      }

      const response = await axios.get(`${this.elevenLabsBaseUrl}/voices`, {
        headers: {
          'xi-api-key': apiKey
        }
      });

      const voices = {};
      response.data.voices.forEach(voice => {
        voices[voice.name] = voice.voice_id;
      });

      return voices;
    } catch (error) {
      console.error('Error fetching voices:', error);
      return this.providers.elevenlabs.voices; // Return default voices on error
    }
  }

  async convertWithWebSpeech(text, voiceId) {
    return new Promise((resolve, reject) => {
      if (!window.speechSynthesis) {
        reject(new Error('Web Speech API is not supported in this browser'));
        return;
      }

      // Cancel any ongoing speech
      window.speechSynthesis.cancel();

      const utterance = new SpeechSynthesisUtterance(text);
      
      // Get available voices
      let voices = window.speechSynthesis.getVoices();
      console.log('Available voices:', voices.map(v => ({
        name: v.name,
        lang: v.lang,
        selected: v.name === voiceId
      })));

      // If voices are not loaded yet, wait for them
      if (voices.length === 0) {
        window.speechSynthesis.onvoiceschanged = () => {
          voices = window.speechSynthesis.getVoices();
          setVoice();
        };
      } else {
        setVoice();
      }

      function setVoice() {
        // Find the selected voice or use the first English voice as fallback
        const selectedVoice = voices.find(v => v.name === voiceId) || 
                            voices.find(v => v.lang.includes('en-')) || 
                            voices[0];
        
        if (selectedVoice) {
          utterance.voice = selectedVoice;
          console.log('Using voice:', selectedVoice.name);
        }
      }

      utterance.rate = 1.0;
      utterance.pitch = 1.0;
      utterance.volume = 1.0;

      utterance.onend = () => {
        console.log('Speech finished');
        resolve(true);
      };

      utterance.onerror = (error) => {
        console.error('Speech error:', error);
        reject(error);
      };

      window.speechSynthesis.speak(utterance);
    });
  }

  async convertWithOpenAI(text, voiceId) {
    try {
      const apiKeys = JSON.parse(localStorage.getItem('apiKeys')) || {};
      const apiKey = apiKeys.openai || process.env.REACT_APP_OPENAI_API_KEY;

      if (!apiKey) {
        throw new Error('OpenAI API key not found. Please add it in settings or subscribe to a plan.');
      }

      const response = await axios.post(
        'https://api.openai.com/v1/audio/speech',
        {
          model: 'tts-1',
          input: text,
          voice: voiceId || 'alloy',
        },
        {
          headers: {
            'Authorization': `Bearer ${apiKey}`,
            'Content-Type': 'application/json'
          },
          responseType: 'arraybuffer'
        }
      );

      const audioBlob = new Blob([response.data], { type: 'audio/mpeg' });
      const audio = new Audio(URL.createObjectURL(audioBlob));
      this.currentAudio = audio;
      
      return new Promise((resolve, reject) => {
        audio.onended = () => {
          this.currentAudio = null;
          URL.revokeObjectURL(audio.src);
          resolve(true);
        };
        audio.onerror = (error) => {
          this.currentAudio = null;
          URL.revokeObjectURL(audio.src);
          reject(error);
        };
        audio.play().catch(reject);
      });
    } catch (error) {
      console.error('OpenAI TTS Error:', error);
      throw error;
    }
  }

  getAvailableProviders() {
    return Object.entries(this.providers).map(([id, provider]) => ({
      value: id,
      label: provider.name
    }));
  }

  getAvailableVoices(provider) {
    const providerVoices = this.providers[provider]?.voices || {};
    return Object.entries(providerVoices).map(([name, id]) => ({
      label: name,
      value: id
    }));
  }
  async convertText(text, provider, voiceId) {
    // If there's already audio playing and it's not paused, stop it
    if (this.currentAudio && !this.isPaused) {
      this.stopPlayback();
    }
    
    console.log('Converting text with:', { provider, voiceId });
    
    try {
      switch (provider) {
        case 'elevenlabs':
          await this.convertWithElevenLabs(text, voiceId);
          break;
        case 'webspeech':
          await this.convertWithWebSpeech(text, voiceId);
          break;
        case 'openai':
          await this.convertWithOpenAI(text, voiceId);
          break;
        case 'google':
          await this.convertWithGoogle(text, voiceId);
          break;
        default:
          throw new Error('Unsupported TTS provider');
      }
    } catch (error) {
      console.error('TTS Error:', error);
      throw error;
    }
  }

   // Add method to load Google voices dynamically
   async loadGoogleVoices() {
    try {
      const apiKey = localStorage.getItem('googleApiKey') || 
                    process.env.REACT_APP_GOOGLE_TTS_API_KEY;
      
      if (apiKey) {
        const response = await axios.get(
          `${this.googleTTSBaseUrl}/voices?key=${apiKey}`,
          {
            params: { languageCode: 'en-US' }
          }
        );

        if (response.data && response.data.voices) {
          const voices = {};
          response.data.voices
            .filter(voice => voice.languageCodes.includes('en-US'))
            .forEach(voice => {
              voices[voice.name] = voice.name;
            });

          // Update the provider's voices
          if (Object.keys(voices).length > 0) {
            this.providers.google.voices = voices;
          }
        }
      }
    } catch (error) {
      console.error('Error loading Google voices:', error);
      // Keep the default voices if loading fails
    }
  }
}

export default new TTSService();
